import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

function checkLoggedIn(to, from, next) {
    if ($cookies.get("id_token") && $cookies.get("user")) {
        next();
    } else {
        next("/login");
    }
}

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/bejelentkezes",
        name: "Login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login.vue")
    },
    {
        path: "/elfelejtett-jelszo",
        name: "ForgetPassword",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "forgot-password" */ "../views/ForgetPassword.vue"
            )
    },
    {
        path: "/jelszo-valtoztatas/:token",
        name: "LostPassword",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "forgot-password" */ "../views/LostPassword.vue"
            )
    },
    {
        path: "/regisztracio",
        name: "Registration",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "registration" */ "../views/Registration.vue"
            )
    },
    {
        path: "/profile",
        name: "ProfileBase",
        beforeEnter: checkLoggedIn,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/ProfileBase.vue")
    },
    {
        path: "/profile/adresses",
        name: "Profile",
        beforeEnter: checkLoggedIn,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
    },
    {
        path: "/profile/orders",
        name: "ProfileOrders",
        beforeEnter: checkLoggedIn,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "profile" */ "../views/ProfileOrders.vue"
            )
    },
    {
        path: "/profile/password",
        name: "ProfilePassword",
        beforeEnter: checkLoggedIn,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "profile" */ "../views/ProfilePassword.vue"
            )
    },
    {
        path: "/rolunk",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue")
    },
    {
        path: "/hirek",
        name: "Blog",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "blog" */ "../views/Blog.vue")
    },
    {
        path: "/cikk/:slug",
        name: "Article",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "article" */ "../views/Article.vue")
    },
    {
        path: "/kapcsolat",
        name: "Contact",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "contact" */ "../views/Contact.vue")
    },
    {
        path: "/kosar-es-fizetes",
        name: "Checkout",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "checkout" */ "../views/Checkout.vue")
    },
    {
        path: "/rendeles-befejezese",
        name: "PaymentFinish",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "payment-finish" */ "../views/PaymentFinish.vue"
            )
    },
    {
        path: "/etlap",
        name: "Menu",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "menu" */ "../views/Menu.vue")
    },
    {
        path: "/galeria",
        name: "Gallery",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "gallery" */ "../views/Gallery.vue")
    },
    {
        path: "/etlap/:slug",
        name: "Product",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "product" */ "../views/Product.vue")
    },
    {
        path: "/az-oldal-nem-talahato",
        name: "NotFound",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "404" */ "../views/NotFound.vue")
    },
    {
        path: "*",
        name: "Static",
        meta: { title: "farmburgervac.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "Static" */ "../views/Static.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes
});

export default router;
