<template>
    <b-modal
        id="modal-1"
        size="lg"
        ref="my-modal"
        title="Kosár"
        centered
        cancel-disabled
        ok-disabled
    >
        <div class="container" v-if="cart && Object.keys(cart).length > 0">
            <CartTable :cart="cart" />
        </div>
        <template #modal-footer="{ }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button
                v-if="
                    cart &&
                        Object.keys(cart).length > 0 &&
                        Object.keys(cart.items).length > 0
                "
                size="sm"
                variant="info"
                @click="update()"
                class="modal-succ"
            >
                Kosár frissítése
            </b-button>
            <b-button
                v-if="
                    cart &&
                        Object.keys(cart).length > 0 &&
                        Object.keys(cart.items).length > 0
                "
                size="sm"
                variant="success"
                @click="checkout()"
                class="modal-succ"
            >
                Megrendelés
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import CartTable from "./CartTable";

export default {
    name: "Cart",
    components: { CartTable },
    beforeMount() {
        this.getCart();
    },
    data() {
        return {
            cart: {
                items: []
            }
        };
    },
    computed: {
        badgeNum: function() {
            let bnum = 0;
            for (const it in this.cart.items) {
                bnum += this.cart.items[it].quantity;
            }
            return bnum;
        }
    },
    watch: {
        badgeNum: function(val) {
            this.$emit("badgeNumChange", val);
        }
    },
    mounted() {
        const self = this;
        this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
            if (bvEvent.type === "show" && modalId === "modal-1") {
                self.getCart();
            }
        });
        this.$eventHub.$on("updateCart", function() {
            self.getCart();
        });
    },
    methods: {
        checkout() {
            this.$refs["my-modal"].hide();
            this.$router.push({ name: "Checkout" });
        },
        update() {
            const self = this;
            const cartUuid = localStorage.getItem("cart_uuid");
            self.$http
                .post(`${self.url}/cart/update/${cartUuid}`, self.cart)
                .then(response => {
                    self.cart = response.data;
                    self.cart.shipment_method_id = null;
                    self.cart.payment_method_id = null;
                    localStorage.setItem("cart_uuid", response.data.uuid);
                    self.$forceUpdate();
                });
        },
        getCart() {
            const self = this;
            const cartUuid = localStorage.getItem("cart_uuid");
            self.$http
                .get(`${self.url}/cart/get/${cartUuid}`)
                .then(response => {
                    self.cart = response.data.data;
                    self.cart.shipment_method_id = null;
                    self.cart.payment_method_id = null;
                    localStorage.setItem("cart_uuid", response.data.data.uuid);
                    self.$forceUpdate();
                })
                .finally(() => {
                    $(document).ready(function() {
                        $("#mask").hide();
                    });
                });
        }
    }
};
</script>

<style lang="scss">
.modal-footer {
    background-color: #454d55 !important;
}

.modal-succ {
    background: #e8bb86 !important;
    background-color: #e8bb86 !important;
    border: 1px solid #e8bb86 !important;
}

#modal-1 .fa {
    color: white !important;
}

.modal-content {
    background-color: #454d55 !important;
    color: white;
}

.modal-content input {
    color: white;
}

.modal-content th,
td,
small,
a,
i {
    color: white;
}
</style>
