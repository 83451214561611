<template>
    <!-- History Section Start -->
    <section id="section-history" data-aos="fade-in" data-aos-duration="1000">
        <div class="container-fluid">
            <div class="row" v-if="model && Object.keys(model).length > 0">
                <div class="col-lg-6 equalHW padding0 col-lg-push-6">
                    <div class="section specialities">
                        <div class="section history">
                            <div class="history-content alignVCenter">
                                <div class="container-col">
                                    <!--<h4 class="small medium right light">Discover your taste</h4>-->
                                    <h4 class="white title">
                                        {{ model.meta_title }}
                                    </h4>
                                    <div class="voffset-30"></div>
                                    <p v-html="model.meta_description"></p>
                                    <div class="voffset-40"></div>
                                    <router-link
                                        :to="{ name: 'Blog' }"
                                        class="theme-btn btn-style-one bt-orange"
                                        style="border: solid 1px #E8BB86;background-color: #E8BB86;"
                                        >Hírek megtekintése
                                        <span
                                            ><img
                                                alt=""
                                                src="/img/demo/dark-version/particles/arrow.svg"/></span
                                    ></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-lg-6 equalHW padding0 col-lg-pull-6 order-first order-md-last"
                >
                    <div
                        v-if="model.og_image"
                        class="history-image"
                        v-bind:style="
                            'background: url(' +
                                model.og_image +
                                ') no-repeat top center #191b1b;background-size:cover;'
                        "
                    ></div>
                    <div v-else class="history-image"></div>
                </div>
            </div>
        </div>
    </section>
    <!-- History Section End -->
</template>

<script>
import Static from "../views/Static";

export default {
    name: "History",
    extends: Static,
    data() {
        return {
            model: {}
        };
    },
    beforeMount() {
        this.$http.get(`${this.url}/blog/get-latest`).then(resp => {
            this.model = resp.data.data;
        });
    }
};
</script>

<style scoped></style>
