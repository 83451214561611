<template>
    <div id="app">
        <LoadingMask />
        <Header :shop="shop" />
        <div class="voffset-131 big offset-mobile-fix"></div>
        <router-view :shop="shop" />
        <Footer :shop="shop" v-if="$route.path !== '/kapcsolat'" />
        <a
            v-if="shop && shop.is_open && $route.name !== 'Checkout'"
            href="#"
            class="float"
            v-b-modal.modal-1
        >
            <i class="fa fa-shopping-cart my-float"></i>
            <b-badge v-if="badgeNum > 0" class="cartBadge" variant="light">{{
                badgeNum
            }}</b-badge>
        </a>
        <Cart v-on:badgeNumChange="badgeNumChange" />
        <!--<div class="badgeTeszt">
            TESZTÜZEM <br /><small
                >Hibabejelentés:
                <a href="mailto:info@farmburgervac.hu"
                    >info@farmburgervac.hu</a
                ></small
            >
        </div>-->
    </div>
</template>

<script>
import LoadingMask from "./components/LoadingMask";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Cart from "./components/Cart";
export default {
    components: { LoadingMask, Header, Footer, Cart },
    data() {
        return {
            shop: {},
            badgeNum: 0
        };
    },
    beforeMount() {
        this.getCart();
        this.$http
            .get(`${this.url}/shop/get/1`)
            .then(resp => {
                this.shop = resp.data.data;
            })
            .finally(() => {
                this.scrollToHash();
            });
    },
    mounted() {
        const self = this;
        self.$eventHub.$on("updateCart", function() {
            self.getCart();
        });
    },
    watch: {
        $route: {
            handler: function() {
                this.scrollToHash();
            },
            deep: true
        }
    },
    methods: {
        scrollToHash() {
            const self = this;
            setTimeout(function() {
                if (self.$route.hash) {
                    const helper = self.$route.hash.replace("#", "");
                    document.getElementById(helper).scrollIntoView({
                        behavior: "smooth"
                    });
                }
            }, 1000);
        },
        badgeNumChange(val) {
            this.badgeNum = val;
        },
        getCart() {
            const self = this;
            const cartUuid = localStorage.getItem("cart_uuid");
            self.$http
                .get(`${self.url}/cart/get/${cartUuid}`)
                .then(response => {
                    localStorage.setItem("cart_uuid", response.data.data.uuid);
                    let bnum = 0;
                    for (const it in response.data.data.items) {
                        bnum += response.data.data.items[it].quantity;
                    }
                    self.badgeNum = bnum;
                })
                .finally(() => {
                    $(document).ready(function() {
                        self.scrollToHash();
                        $("#mask").hide();
                    });
                });
        }
    }
};
</script>

<style lang="scss">
html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

.offset-mobile-fix {
    margin-bottom: 250px !important;
}

@media (max-width: 480px) {
    .offset-mobile-fix {
        margin-bottom: 25px !important;
    }
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #e8bb86;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    z-index: 10000;
}

.my-float {
    margin-top: 22px;
}

.slick-arrow.slick-prev {
    right: 52px !important;
    background: url(/img/bt-arrow-gray.13a16da5.svg) no-repeat center #f0f0f0 !important;
    transform: rotate(180deg) !important;
    background-size: 8px 13px !important;
}

.slick-arrow.slick-next {
    background: url(/img/bt-arrow-gray.13a16da5.svg) no-repeat center #e2dede !important;
    background-size: 8px 13px !important;
}

.cartBadge {
    right: 0;
    bottom: 0;
    position: absolute;
}

/* Mixin */
.badgeTeszt {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #f37c32;
    text-align: center;
    color: white;
    font-weight: bold;
}
</style>
