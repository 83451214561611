<template>
    <!-- Navigation -->
    <header
        class="container-fluid header js-header-fixsmall overlayer-fixed"
        id="myHeader"
    >
        <div class="row justify-content-center" v-if="shop">
            <div
                class="col-md-12 top-bar brand-color"
                v-if="!shop.is_open && shop.holiday && shop.holiday.description"
            >
                <b v-html="shop.holiday.description"></b>
            </div>
        </div>
        <div
            class="row justify-content-center"
            v-if="shop && !shop.is_open && shop.id && !shop.holiday"
        >
            <div class="col-md-12 top-bar brand-color">
                <b>Az üzlet jelenleg zárva!</b>
            </div>
        </div>
        <!-- Start Navbar-->
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="row justify-content-between">
                    <div class="col-auto">
                        <router-link class="navbar-brand" to="/"
                            ><img
                                :src="whiteLogoUrl"
                                class="img img-responsive"
                                alt="FARM Burger"
                        /></router-link>
                        <!--<router-link
                            class="navbar-brand black d-none d-sm-block d-md-none"
                            to="/"
                            ><img :src="blackLogoUrl"
                        /></router-link>-->
                    </div>
                    <div class="d-flex justify-content-center onlydesktop">
                        <ul class="navbar">
                            <li
                                v-for="(item, index) in menu.items"
                                v-bind:key="'menu-' + index"
                            >
                                <a
                                    style="cursor: pointer"
                                    :to="item.link"
                                    v-on:click.prevent="scrollAction(item.link)"
                                    class="nav-link"
                                    v-bind:class="{
                                        active: item.link === $route.path
                                    }"
                                    >{{ item.title }}</a
                                >
                            </li>
                            <!--<li v-if="user && Object.keys(user).length > 0">
                                <a
                                    href="#"
                                    v-on:click.prevent="logout"
                                    class="nav-link"
                                    >Kijelentkezés</a
                                >
                            </li>-->
                        </ul>
                    </div>
                    <div class="d-flex justify-content-center onlydesktop">
                        <ul class="navbar float-right onlydesktop">
                            <li v-if="!user || Object.keys(user).length < 1">
                                <router-link
                                    :to="{ name: 'Login' }"
                                    class="bt-orange bt-menu-reserve animatedScroll"
                                    >Belépés</router-link
                                >
                            </li>
                            <li v-else>
                                <router-link
                                    :to="{ name: 'ProfileBase' }"
                                    class="bt-orange bt-menu-reserve animatedScroll"
                                    >Profilom</router-link
                                >
                            </li>
                            <li>
                                <a
                                    class="fb-ic mr-3"
                                    href="https://www.facebook.com/farmvac"
                                    target="_blank"
                                    role="button"
                                    ><i class="fab fa-lg fa-facebook-f"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    class="tw-ic mr-3"
                                    href="https://www.tripadvisor.com/Restaurant_Review-g1102816-d15322017-Reviews-Farm_Burger_Vac-Vac_Pest_County_Central_Hungary.html"
                                    target="_blank"
                                    role="button"
                                    ><i class="fab fa-lg fa-tripadvisor"></i
                                ></a>
                            </li>
                            <li v-if="shop && shop.phone">
                                <a
                                    class="tw-ic mr-3"
                                    :href="'tel:' + shop.phone"
                                    target="_blank"
                                    role="button"
                                    ><i class="fa fab fa-lg fa-phone"></i
                                ></a>
                            </li>
                        </ul>
                        <ul class="navbar">
                            <li class="hambuger onlyresponsive">
                                <button
                                    id="trigger-overlay"
                                    class="trigger-overlay dropdown-icon"
                                >
                                    <span></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="d-flex justify-content-end onlymobile">
                        <ul class="navbar float-right onlydesktop">
                            <li v-if="!user || Object.keys(user).length < 1">
                                <router-link
                                    :to="{ name: 'Login' }"
                                    class="bt-orange bt-menu-reserve animatedScroll"
                                    >Belépés</router-link
                                >
                            </li>
                            <li v-else>
                                <router-link
                                    :to="{ name: 'ProfileBase' }"
                                    class="bt-orange bt-menu-reserve animatedScroll"
                                    >Profilom</router-link
                                >
                            </li>
                            <li>
                                <a
                                    class="fb-ic mr-3"
                                    href="https://www.facebook.com/farmvac"
                                    target="_blank"
                                    role="button"
                                    ><i class="fab fa-lg fa-facebook-f"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    class="tw-ic mr-3"
                                    href="https://www.tripadvisor.com/Restaurant_Review-g1102816-d15322017-Reviews-Farm_Burger_Vac-Vac_Pest_County_Central_Hungary.html"
                                    target="_blank"
                                    role="button"
                                    ><i class="fab fa-lg fa-tripadvisor"></i
                                ></a>
                            </li>
                            <li v-if="shop && shop.phone">
                                <a
                                    class="tw-ic mr-3"
                                    :href="'tel:' + shop.phone"
                                    target="_blank"
                                    role="button"
                                    ><i class="fa fab fa-lg fa-phone"></i
                                ></a>
                            </li>
                        </ul>
                        <ul class="navbar">
                            <li class="hambuger onlyresponsive">
                                <button
                                    id="trigger-overlay"
                                    class="trigger-overlay dropdown-icon"
                                >
                                    <span></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlay overlay-hugeinc">
            <div class="overlay-content">
                <span class="trigger-overlay"></span>
                <nav id="nav">
                    <ul>
                        <li
                            v-for="(item, index) in menu.items"
                            v-bind:key="'menu-' + index"
                            v-bind:class="{
                                current: item.link === $route.path
                            }"
                        >
                            <template v-if="item.link.includes('#')">
                                <a :href="getLinkType(item.link)">{{
                                    item.title
                                }}</a>
                            </template>
                            <template v-else>
                                <router-link :to="item.link">{{
                                    item.title
                                }}</router-link>
                            </template>
                        </li>
                        <li v-if="!user || Object.keys(user).length < 1">
                            <router-link :to="{ name: 'Login' }"
                                >Belépés</router-link
                            >
                        </li>
                        <template v-else>
                            <li>
                                <router-link :to="{ name: 'ProfileBase' }"
                                    >Profilom</router-link
                                >
                            </li>
                            <!--<li>
                                <a href="#" v-on:click.prevent="logout"
                                    >Kijelentkezés</a
                                >
                            </li>-->
                        </template>
                    </ul>
                </nav>
            </div>
        </div>
        <!-- End Navbar-->
    </header>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Header",
    props: ["shop"],
    data() {
        return {
            menu: {
                items: []
            },
            user: {}
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    beforeMount() {
        const self = this;
        self.getUserData();

        self.$eventHub.$off("userChanged");
        self.$eventHub.$on("userChanged", function() {
            self.getUserData();
        });
    },
    mounted() {
        this.getMenu();

        window.onscroll = function() {
            myFunction();
        };

        // Get the header
        var header = document.getElementById("myHeader");

        // Get the offset position of the navbar
        var sticky = header.offsetTop;

        // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
    },
    methods: {
        scrollAction(link) {
            if (
                this.$route.hash === "#section-specialities" &&
                link === "/#section-specialities"
            ) {
                document
                    .getElementById("section-specialities")
                    .scrollIntoView();
            } else {
                this.$router.push(link);
            }
        },
        logout() {
            $cookies.remove("user");
            $cookies.remove("id_token");
            document.location.href = "/";
        },
        getUserData() {
            if ($cookies.get("id_token")) {
                ApiService.get(`${this.url}/user/profile`)
                    .then(response => {
                        this.user = response.data.data;
                        $cookies.set("user", response.data.data, 86400);
                    })
                    .catch(() => {
                        $cookies.remove("user");
                        $cookies.remove("id_token");
                    });
            }
        },
        getLinkType(link) {
            if (link.includes("#") && this.$route.name === "Home") {
                return link.replace("/", "");
            }
            return link;
        },
        getMenu() {
            this.$http
                .get(`${this.url}/menu/get/1`)
                .then(resp => {
                    this.menu = resp.data.data;
                })
                .finally(() => {
                    this.triggerOverlay();
                });
        },
        triggerOverlay() {
            $(".trigger-overlay").length > 0 &&
                ($(".trigger-overlay").click(function() {
                    return (
                        $(".overlay").hasClass("open")
                            ? ($(".overlay").removeClass("open"),
                              $(this).removeClass("is-active"))
                            : ($(".overlay").addClass("open"),
                              $(this).addClass("is-active")),
                        !1
                    );
                }),
                $(".overlay")
                    .find("a")
                    .on("click", function() {
                        $(".overlay").removeClass("open"),
                            $(".dropdown-icon").removeClass("is-active");
                    }));
        }
    }
};
</script>

<style scoped>
.navbar-brand img {
    max-width: 80px !important;
}

@media only screen and (max-width: 600px) {
    .navbar-brand img {
        max-width: 70px !important;
    }
}

.top-bar {
    color: white;
    font-size: 14px;
}

.brand-color {
    color: #e8bb86;
}

.closed-btn {
    background: url(/img/bg-booking.d4b1933f.jpg) no-repeat center !important;
    background-size: cover !important;
    cursor: not-allowed;
}

.shop_text {
    color: white;
    white-space: pre-line;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    background: black !important;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
    padding-top: 102px;
}

@media (min-width: 1100px) {
    .onlymobile {
        display: none !important;
    }
}
</style>
