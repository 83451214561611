import toastr from "toastr";
export default {
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        },
        appUrl: function() {
            return `${process.env.VUE_APP_APP_URL}`;
        },
        whiteLogoUrl: function() {
            return `${this.appUrl}/img/Farm_Logo_Original.png`;
        },
        blackLogoUrl: function() {
            return `${this.appUrl}/img/Farm_Logo_Original.png`;
        }
    },
    methods: {
        successAlert(line1, line2) {
            toastr.success(line1, line2);
        },
        metaCreation(pageData) {
            let metaTags = [];

            if (pageData.meta_title) {
                metaTags["meta_title"] =
                    pageData.meta_title + " - " + process.env.VUE_APP_APP_NAME;
                document.title =
                    pageData.meta_title + " - " + process.env.VUE_APP_APP_NAME;
            } else if (pageData.title) {
                metaTags["meta_title"] =
                    pageData.title + " - " + process.env.VUE_APP_APP_NAME;
                document.title =
                    pageData.title + " - " + process.env.VUE_APP_APP_NAME;
            } else if (pageData.name) {
                metaTags["meta_title"] =
                    pageData.name + " - " + process.env.VUE_APP_APP_NAME;
                document.title =
                    pageData.name + " - " + process.env.VUE_APP_APP_NAME;
            } else {
                metaTags["meta_title"] = process.env.VUE_APP_APP_NAME;
                document.title = process.env.VUE_APP_APP_NAME;
            }

            metaTags["og:image"] = pageData.og_image;
            metaTags["meta_description"] = pageData.meta_description;

            if (!pageData.og_image) {
                if (pageData.image) {
                    metaTags["og:image"] = pageData.image;
                } else if (pageData.main_image) {
                    metaTags["og:image"] = pageData.main_image;
                } else {
                    metaTags["og:image"] = "/img/logo.svg";
                }
            }

            if (!pageData.meta_description) {
                metaTags["meta_description"] = pageData.lead;
            }

            for (const it in metaTags) {
                if (document.getElementsByName(it).length < 1) {
                    const tag = document.createElement("meta");
                    tag.setAttribute("name", it);
                    tag.setAttribute("content", metaTags[it]);
                    tag.setAttribute("data-vue-router-controlled", "");

                    document.head.appendChild(tag);
                } else {
                    document
                        .getElementsByName(it)[0]
                        .setAttribute("content", metaTags[it]);
                    document
                        .getElementsByName(it)[0]
                        .setAttribute("data-vue-router-controlled", "");
                }
            }
        },
        setMetaTags(data) {
            document.title =
                data.meta_title + " - " + process.env.VUE_APP_APP_NAME;

            if (document.getElementsByName("robots").length > 0) {
                document.getElementsByName("robots")[0].remove();
            }

            this.createOrReplaceMeta(
                "og:title",
                data.meta_title + " - " + process.env.VUE_APP_APP_NAME
            );
            this.createOrReplaceMeta("description", data.meta_description);
            if (data.og_image) {
                this.createOrReplaceMeta("og:image", data.og_image);
            } else {
                this.createOrReplaceMeta("og:image", "/frontend/img/logo.svg");
            }
            this.createOrReplaceMeta("og:url", document.location.href);
        },

        historyGetByRoute(path) {
            let history = localStorage.getItem("urlHistoryList");
            if (!history || !history.length) {
                return path;
            }
            history = JSON.parse(history);
            if (!history[path]) {
                return path;
            }
            return history[path];
        },
        createOrReplaceMeta(it, data) {
            if (document.getElementsByName(it).length < 1) {
                const tag = document.createElement("meta");
                tag.setAttribute("name", it);
                tag.setAttribute("content", data);
                tag.setAttribute("data-vue-router-controlled", "");

                document.head.appendChild(tag);
            } else {
                document.getElementsByName(it)[0].setAttribute("content", data);
                document
                    .getElementsByName(it)[0]
                    .setAttribute("data-vue-router-controlled", "");
            }
        }
    }
};
