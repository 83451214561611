<template>
    <div class="cartTable" v-if="cart && cart.items">
        <template v-if="cart.items.length < 1">
            <div class="row">
                <div class="col-12 text-center">
                    <h5 class="white title" style="color: black !important;">
                        Jelenleg üres a kosarad.
                    </h5>
                    <div class="voffset-90"></div>
                    <div class="voffset-90"></div>
                    <div class="voffset-90"></div>
                </div>
            </div>
        </template>
        <template v-if="cart.items.length > 0">
            <div class="row ">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        class="d-none d-sm-block"
                                        style="border-bottom: none !important;"
                                    >
                                        &nbsp;
                                    </th>
                                    <th scope="col">Termék</th>
                                    <th scope="col">
                                        Mennyiség
                                    </th>
                                    <th scope="col">Összeg</th>
                                    <th scope="col">
                                        Műveletek
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in cart.items"
                                    v-bind:key="'cart-item-' + index"
                                >
                                    <th scope="row" class="d-none d-sm-block">
                                        <img
                                            v-if="item.product.image"
                                            class="img-thumbnail"
                                            style="max-width: 100px"
                                            :src="item.product.image"
                                        />
                                        <img
                                            v-else
                                            class="img-thumbnail"
                                            style="max-width: 100px"
                                            src="/img/Farm_Logo_Original.png"
                                        />
                                    </th>
                                    <td>
                                        <b>{{ item.product.name }}</b
                                        ><br />
                                        <small v-html="item.extras"></small>
                                        <br />
                                        <small v-html="item.comment"></small>
                                    </td>
                                    <td>
                                        <b-input-group size="sm" append="db">
                                            <b-form-input
                                                type="number"
                                                min="1"
                                                max="16"
                                                v-model="item.quantity"
                                            ></b-form-input>
                                        </b-input-group>
                                    </td>
                                    <td>
                                        {{ item.total_formatted }}
                                    </td>
                                    <td style="text-align: center">
                                        <a
                                            href="#"
                                            v-on:click.prevent="
                                                deleteFromCart(item.id)
                                            "
                                            title="Törlés"
                                            ><i
                                                style="color: #000"
                                                class="fa fa-trash"
                                            ></i
                                        ></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "CartTable",
    props: ["cart"],
    methods: {
        deleteFromCart(id) {
            const self = this;
            const cartUuid = localStorage.getItem("cart_uuid");
            self.$http
                .delete(`${self.url}/cart/delete/${cartUuid}/${id}`)
                .then(response => {
                    self.cart.items = response.data.items;
                    self.$forceUpdate();
                    self.$emit("updateCart", true);
                });
        }
    }
};
</script>

<style scoped>
.cartTable small {
    color: white !important;
}

.form-control:focus,
.form-control:active {
    color: white !important;
}
</style>
