var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"my-modal",attrs:{"id":"modal-1","size":"lg","title":"Kosár","centered":"","cancel-disabled":"","ok-disabled":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){return [(
                _vm.cart &&
                    Object.keys(_vm.cart).length > 0 &&
                    Object.keys(_vm.cart.items).length > 0
            )?_c('b-button',{staticClass:"modal-succ",attrs:{"size":"sm","variant":"info"},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Kosár frissítése ")]):_vm._e(),(
                _vm.cart &&
                    Object.keys(_vm.cart).length > 0 &&
                    Object.keys(_vm.cart.items).length > 0
            )?_c('b-button',{staticClass:"modal-succ",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.checkout()}}},[_vm._v(" Megrendelés ")]):_vm._e()]}}])},[(_vm.cart && Object.keys(_vm.cart).length > 0)?_c('div',{staticClass:"container"},[_c('CartTable',{attrs:{"cart":_vm.cart}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }