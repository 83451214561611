<template>
    <div class="home">
        <!--<Slider />-->
        <div class="voffset-131"></div>
        <FullMenu />
        <!--<div class="voffset-131"></div>
        <History />-->
        <!--<div class="voffset-131"></div>
        <Specialities />-->
        <!--<div class="voffset-131"></div>
        <Testimonials />-->
    </div>
</template>

<script>
import Slider from "../components/Slider";
import FullMenu from "../components/FullMenu";
import History from "../components/History";
//import Testimonials from "../components/Testimonials";
import Specialities from "../components/Specialities";
export default {
    name: "Home",
    components: { Specialities, Slider, FullMenu, History /*, Testimonials*/ }
};
</script>
