<template>
    <!-- Start  Loading Mask-->
    <div id="mask">
        <div class="material-icon">
            <div class="spinner">
                <div class="right-side">
                    <div class="bar"></div>
                </div>
                <div class="left-side">
                    <div class="bar"></div>
                </div>
            </div>
            <div class="spinner color-2">
                <div class="right-side">
                    <div class="bar"></div>
                </div>
                <div class="left-side">
                    <div class="bar"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Loading Mask-->
</template>

<script>
export default {
    name: "LoadingMask"
};
</script>

<style scoped></style>
