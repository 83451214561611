<template>
    <img v-lazy="src" :title="title" :class="imgClass" />
</template>

<script>
export default {
    name: "LazyImage",
    props: ["src", "title", "imgClass"]
};
</script>

<style scoped></style>
