<template>
    <!-- Footer Section Start -->
    <footer>
        <div class="voffset-50"></div>
        <div class="row">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <img
                            src="/img/farm-belso.jpg"
                            class="img-fluid belsokep"
                            alt="FARM Burger Belső tér"
                        />
                    </div>
                    <div
                        class="col-md-3 shop_text"
                        v-html="shop.header_text"
                    ></div>
                    <div class="col-md-3">
                        <ul class="foot-social" style="padding: 0">
                            <li>
                                <a
                                    class="fb-ic mr-3"
                                    href="https://www.facebook.com/farmvac"
                                    target="_blank"
                                    role="button"
                                    ><i class="fab fa-lg fa-facebook-f"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    class="tw-ic mr-3"
                                    href="https://www.tripadvisor.com/Restaurant_Review-g1102816-d15322017-Reviews-Farm_Burger_Vac-Vac_Pest_County_Central_Hungary.html"
                                    target="_blank"
                                    role="button"
                                    ><i class="fab fa-lg fa-tripadvisor"></i
                                ></a>
                            </li>
                            <li v-if="shop && shop.phone">
                                <a
                                    class="tw-ic mr-3"
                                    :href="'tel:' + shop.phone"
                                    target="_blank"
                                    role="button"
                                    ><i class="fa fab fa-lg fa-phone"></i
                                ></a>
                            </li>
                            <li v-if="shop && shop.email">
                                <a
                                    class="tw-ic mr-3"
                                    :href="'mailto:' + shop.email"
                                    target="_blank"
                                    role="button"
                                    ><i class="fa fab fa-lg fa-envelope"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    class="tw-ic mr-3"
                                    href="https://g.page/farmvac?share"
                                    target="_blank"
                                    role="button"
                                    ><i
                                        class="fa fab fa-lg fa-location-arrow"
                                    ></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <ul style="padding: 0">
                            <li>
                                <router-link to="/gyik">GY.I.K.</router-link>
                            </li>
                            <li><router-link to="/aszf">ÁSZF</router-link></li>
                            <li>
                                <router-link to="/adatkezeles"
                                    >Adatkezelési tájékoztató</router-link
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="voffset-50"></div>
            </div>
            <div class="container-fluid">
                <div class="col-12 copyright">
                    <div class="voffset-20"></div>
                    <div class="row">
                        <p>Copyright {{ actualYear }} FARM Burger Vác</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Section End -->
</template>

<script>
export default {
    name: "Footer",
    props: ["shop"],
    computed: {
        actualYear() {
            return new Date().getFullYear();
        }
    }
};
</script>

<style scoped>
footer .logo {
    max-width: 100px !important;
}

.contact_footer span {
    display: block;
    white-space: nowrap;
}
.foot-logo {
    text-align: center;
}

footer {
    color: white !important;
}

footer li {
    list-style: none;
    margin-bottom: 15px;
}

footer .col-md-3 {
    text-align: center;
}

.foot-social li {
    display: inline-block;
}

footer .mr-3 {
    top: 0px;
}

footer .shop_text {
    margin-bottom: 25px;
    line-height: 1.8;
    letter-spacing: 5px;
}

.belsokep {
    margin-bottom: 25px;
}
</style>
