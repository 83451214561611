<template>
    <!-- Specialities Section Start -->
    <section
        v-if="model && model.length > 0"
        id="section-specialities"
        data-aos="fade-down"
        data-aos-duration="1000"
    >
        <div class="container">
            <div class="row ">
                <div class="col-lg-12">
                    <h4 class="small center">{{ model[0].name }}</h4>
                    <h4 class="center title">{{ model[0].subname }}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="section specialities">
                        <div class="voffset-50"></div>
                        <div class="row">
                            <div
                                class="contentslide slide-specialities row"
                                style="justify-content: center;"
                            >
                                <template v-for="(item, index) in model">
                                    <div
                                        class="col-lg-6 equalHW"
                                        style="flex: 50%; margin-bottom: 10px"
                                        v-if="index > 0"
                                        v-bind:key="'specialities-' + index"
                                    >
                                        <div
                                            class="sliderslide-content specialities"
                                            style="min-height: 410px"
                                        >
                                            <img
                                                :src="item.icon"
                                                :style="item.icon_color"
                                                class="filter"
                                                :alt="item.name"
                                            />
                                            <div class="voffset-15"></div>
                                            <h4>{{ item.name }}</h4>
                                            <div class="voffset-20"></div>
                                            <p v-html="item.description"></p>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="voffset-80"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Specialities Section Start -->
</template>

<script>
export default {
    name: "Specialities",
    data() {
        return {
            model: []
        };
    },
    beforeMount() {
        this.$http.get(`${this.url}/mission/get`).then(resp => {
            this.model = resp.data.data;
        });
    }
};
</script>

<style></style>
