<template>
    <!-- Hero Section  Start -->
    <section
        id="section-home"
        class="slider slide-overlay-black"
        v-if="model.length > 0"
    >
        <!-- START REVOLUTION -->
        <div class="rev_slider_wrapper">
            <div id="slider1" class="rev_slider" data-version="5.0">
                <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="4000"
                    :controls="enableControl"
                    :indicators="enableControl"
                    background="#ababab"
                    img-width="1024"
                    img-height="480"
                    style="text-shadow: 1px 1px 2px #333;"
                >
                    <b-carousel-slide
                        v-for="(item, index) in model"
                        v-bind:key="'slider-' + index"
                        :caption="item.line_1"
                        :img-src="item.image"
                        :img-alt="item.line_1"
                    >
                        <p v-html="item.line_2"></p>
                    </b-carousel-slide>
                </b-carousel>
            </div>
            <!-- END REVOLUTION SLIDER -->
        </div>
    </section>
    <!-- Hero Section End -->
</template>

<script>
export default {
    name: "Slider",
    data() {
        return {
            slide: 0,
            model: {}
        };
    },
    beforeMount() {
        this.getSlider();
    },
    computed: {
        enableControl() {
            return this.model.length > 1;
        }
    },
    methods: {
        getSlider() {
            this.$http.get(`${this.url}/slider/get`).then(resp => {
                this.model = resp.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
